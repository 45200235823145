import Vue from "vue";

export const store = Vue.observable({
    isNavOpen: false,
    isModalOpen: false,
    isNavDropdownOpen: false,
    isNavDropdownOpenTwo: false,
    isOpen: false,
});

export const mutations = {
    toggleNav() {
        store.isNavOpen = !store.isNavOpen;
    },
    toggleModal() {
        store.isModalOpen = !store.isModalOpen;
    },
    toggleNavDropdown() {
        store.isNavDropdownOpen = !store.isNavDropdownOpen;
    },
    toggleNavDropdownTwo() {
        store.isNavDropdownOpenTwo = !store.isNavDropdownOpenTwo;
    },
    toggleIcon() {
        this.isOpen = !this.isOpen;
    },
};
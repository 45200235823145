import VueRouter from "vue-router";
import Vue from "vue";
import App from "./App.vue";
import { routes } from "./routes.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import Spinner from "./components/Animations/LoadSpinner";
import Firebase from "firebase/app";
import 'firebase/firestore';
import AOS from 'aos'
import 'aos/dist/aos.css'

import { VuePlausible } from 'vue-plausible'

Vue.use(VuePlausible, {
    // see configuration section
})

Vue.$plausible.enableAutoPageviews()

library.add(faTimes, faHome, faInstagram, faArrowDown);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueRouter);

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
};
Firebase.initializeApp(firebaseConfig);

//Router
const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: "history",
    routes,
});

Vue.config.productionTip = false;
Vue.component("loading", Spinner);

const app = new Vue({
    created() {
        AOS.init()
    },
    render: (h) => h(App),
    data: { loading: false },
    router,

}).$mount("#app");

router.beforeEach((to, from, next) => {
    app.loading = true;
    next();
});

router.afterEach(() => {
    setTimeout(() => (app.loading = false), 1000); // timeout
    //app.loading = false;
});
import ImageGridResponsive from "./components/ImageGallery/ImageGridResponsive.vue";
import Home from "./Modules/Main/index.vue";
import Uvdal from "./components/Places/uvdal.vue";
import Bremanger from "./components/Places/bremanger.vue";

export const routes = [{
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/yearbook/2017",
        name: "yearbook-2017",
        component: ImageGridResponsive,
        props: { year: 2017 },
    },
    {
        path: "/yearbook/2018",
        name: "yearbook-2018",
        component: ImageGridResponsive,
        props: { year: 2018 },
    },
    {
        path: "/yearbook/2019",
        name: "yearbook-2019",
        component: ImageGridResponsive,
        props: { year: 2019 },
    },
    {
        path: "/yearbook/2020",
        name: "yearbook-2020",
        component: ImageGridResponsive,
        props: { year: 2020 },
    },
    {
        path: "/yearbook/2021",
        name: "yearbook-2021",
        component: ImageGridResponsive,
        props: { year: 2021 },
    },
    {
        path: "/yearbook/2022",
        name: "yearbook-2022",
        component: ImageGridResponsive,
        props: { year: "2022" },
    },
    {
        path: "/yearbook/eldre",
        name: "yearbook-eldre",
        component: ImageGridResponsive,
        props: { year: "eldre" },
    },
    {
        path: "/uvdal",
        name: "uvdal",
        component: Uvdal,
    },
    {
        path: "/bremanger",
        name: "bremanger",
        component: Bremanger,
    },
];
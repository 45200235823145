<template>
  <div>
    <!-- Photo Grid -->
    <div class="row">
      <div class="column">
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
        >
          <slot name="image-1" />
        </div>
      </div>
      <div class="column">
        <div
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2300"
        >
          <slot name="image-2" />
        </div>
      </div>
      <div class="column">
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2800"
        >
          <slot name="image-3" />
        </div>
      </div>

      <div class="column">
        <div
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2500"
        >
          <slot name="image-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style >
.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
</style>
<template>
  <div class="index">
    <CenteredContendCard />
    <div
      data-aos="fade-in"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1500"
      data-aos-anchor-placement="turer"
      id="turer"
      class="category-title"
    >
      Mine turer
    </div>
    <TextOnImage :items="adventures" />
    <div
      data-aos="fade-in"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
      data-aos-anchor-placement="turer"
      id="arkiv"
      class="category-title"
    >
      Fotoarkiv
    </div>
    <TextOnImage :items="archives" />
  </div>
</template>

<script>
import CenteredContendCard from "./CenterContentCard.vue";
import TextOnImage from "./TextOnImage.vue";
export default {
  name: "index",
  components: {
    CenteredContendCard,
    TextOnImage,
  },
  data() {
    return {
      //eventyr-historier
      adventures: [
        {
          tittel: "Uvdal",
          link: "/uvdal",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fuvdal_host_L.jpg?alt=media&token=76a7c481-a1a0-4620-936c-0c7ad4003f22",
          alt: "høstlandskap med snø og høstfarger",
        },
        {
          tittel: "Bremanger",
          link: "/bremanger",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_5715.jpg?alt=media&token=e89c002e-366d-4e06-968a-754cc18dd2bc",
          alt: "Spor i vetvika",
        },
      ],

      // arkiv
      archives: [
        {
          tittel: "2022",
          link: "/yearbook/2022",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2022%2FDSC02670.jpg?alt=media&token=85caa55f-d0aa-4b0a-8a24-337cab06d5a9",
          alt: "snødekt Hardangervidda",
        },
        {
          tittel: "2021",
          link: "/yearbook/2021",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2FS%C3%B8renga%2FIMG_6174.jpg?alt=media&token=52e4502d-20fd-421f-8b1e-8784dd291bf3",
          alt: "Toppen av munchmuseet i oslo",
        },
        {
          tittel: "2020",
          link: "/yearbook/2020",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/forside%2Fvaar_oslo_L.jpg?alt=media&token=913b0ea8-8493-488c-8873-6734cca89c47",
          alt: "Rosa vårblomster i oslo",
        },
        {
          tittel: "2019",
          link: "/yearbook/2019",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/forside%2Fbarn_botanisk_L.jpg?alt=media&token=dccfb59a-0f4c-4389-ac3d-4797e08ac014",
          alt: "et barn med ryggen til leker i botanisk hage",
        },
        {
          tittel: "2018",
          link: "/yearbook/2018",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/forside%2Fchili_L.jpg?alt=media&token=c9f3ee0b-71f1-4ebb-ae1c-b4128946f81a",
          alt: "chili og morter",
        },
        {
          tittel: "2017",
          link: "/yearbook/2017",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/forside%2Fkeiservarden_L.jpg?alt=media&token=36cde7ec-3783-4b8f-ac0c-184124f40166",
          alt: "midnattsol fra Keiservarden i Bodø",
        },
        {
          tittel: "Eldre",
          link: "/yearbook/eldre",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/forside%2Feldre_fjell_L.jpg?alt=media&token=2daef2e4-b690-4ed4-b8ee-3b1e39abe470",
          alt: "Mørkt fjell i tåke",
        },
      ],
    };
  },
};
</script>

<style>
@import url("https://use.typekit.net/djf0wvu.css");

.index {
  font-family: freight-neo-pro, sans-serif;
  font-weight: 400;
  font-size: 5vw;
  color: #504e4c;
}
.category-title {
  overflow: hidden;
  margin-left: 8vw;
  margin-bottom: -4vw;
}

.category-title:after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 25%;
  margin-right: -100%;
  margin-left: 2vw;
  border-top: 2px solid #504e4c;
}
</style>
<template>
  <div>
    <footer class="footer grid-container">
      <div class="logo">
        <a>
          <router-link to="/"
            ><img
              alt="logo med fjellmotiv formet som en C"
              class="logoimg-large"
              src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/logo.png?alt=media&token=6f827ec0-587a-46bc-97b2-2d64e9a035c9"
          /></router-link>
        </a>
      </div>
      <div class="content">
        <p class="social">
          Spørsmål? Send meg en mail:<br />
          ceciliehrr@gmail.com
        </p>
        <p class="social">
          Følg meg på instagram:<br />
          <a href="https://www.instagram.com/ceciliefoto.no/">
            <font-awesome-icon id="insta" :icon="['fab', 'instagram']" />
            ceciliefoto.no</a
          >
        </p>
        <p class="copyright">
          Copyright &copy; 2020-2022 All Rights Reserved Cecilie Hansen Rørås.
        </p>
        <hr />
      </div>
      <div class="gdpr">
        Jeg er ikke en stalker! Dette er en GDPR-vennlig nettside, jeg lagrer
        ingenting og selger ingenting som kan identifisere deg som person. Les
        mer om mitt valg av verktøy for besøksstatistikk
        <a
          role="klikk til ny nettside"
          href="https://plausible.io/privacy-focused-web-analytics"
          >Plausible her!</a
        >
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  margin: 1rem;
  text-align: center;
  padding: 1rem;
}
.logo {
  grid-area: logo;
}
.content {
  grid-area: content;
}
.gdpr {
  grid-area: gdpr;
  font-size: 14px;
  padding: 1rem;
}
.copyright {
  grid-area: copyright;
  font-size: 14px;
  margin-top: 1rem;
}

.social {
  grid-area: social;
  font-size: 18px;
  padding: 0.8rem;
}
#insta {
  vertical-align: text-bottom;
}
.grid-container {
  grid-template-areas:
    "logo logo logo logo logo logo"
    "content content content content content content"
    "gdpr gdpr gdpr gdpr gdpr gdpr";
}
a {
  font-weight: bold;
}
h3,
p {
  margin: 10px;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#insta {
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
</style>

<template>
  <div id="app">
    <Spinner />
    <nav class="main-nav">
      <div class="logo">
        <a>
          <router-link to="/"
            ><img
              alt="logo med fjellmotiv formet som en C"
              class="logoimg-small"
              src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/logo.png?alt=media&token=6f827ec0-587a-46bc-97b2-2d64e9a035c9"
            />ceciliefoto</router-link
          >
        </a>
      </div>
      <Burger></Burger>
    </nav>
    <MainNav />
    <router-view class="fade"></router-view>
    <Footer />
  </div>
</template>

<script>
import { mutations, store } from "@/store.js";
import MainNav from "./Modules/Main/MainNav.vue";
import Burger from "./components/Menu/Hamburger.vue";
import Footer from "./components/Menu/Footer.vue";
import Spinner from "./components/Animations/LoadSpinner";
export default {
  name: "App",
  components: {
    Burger,
    Footer,
    Spinner,
    MainNav,
  },
  methods: {
    closeSidebarPanel: mutations.toggleNav,
    openNavDropdown: mutations.toggleNavDropdown,
  },
  computed: {
    isNavDropdownOpen() {
      return store.isNavDropdownOpen;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unica+One&display=swap");
* {
  box-sizing: border-box;
}
html,
body,
#app {
  height: 100%;
  margin: 0;
}

#app {
  font-family: "Recursive", sans-serif;
  font-weight: 400;
  margin-top: 45px;
}
h3 {
  font-family: "Recursive", sans-serif;
  font-weight: 400;
}
a {
  font-family: "Recursive", sans-serif;
  font-weight: 300;
  text-decoration: none;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
p {
  font-family: "Recursive", sans-serif;
  font-weight: 400;
}
body {
  border: 0;
  margin: 0;
  padding: 0;
}
.logo {
  align-self: center;
  color: black;
}
.logoimg-small {
  height: 30px;
}
.logoimg-large {
  height: 50px;
}
nav {
  background-color: white;
}
.main-nav {
  top: 0;
  z-index: 1;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}

.nav-dropdown {
  position: static;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}
.fade {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1059px) {
  a {
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  a {
    font-size: 15px;
  }
  #app {
    margin-top: 40px;
  }
}
</style>

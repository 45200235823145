<template>
  <!-- put element here-->
  <div class="imagegrid">
    <transition appear name="slide-in">
      <div class="year-text">{{ this.year }}</div>
    </transition>
    <div v-for="imageObject in filteredImageFolder" :key="imageObject.id">
      <div class="grid-wrapper">
        <Card
          v-for="(card, index) in imageObject.images"
          :key="'card' + index"
          @click.native.prevent="toggle"
          @click.native="passItem(card, index)"
          @click="passIndex(index)"
          :item="card"
          :index="index"
        />
      </div>
    </div>

    <ImageModal v-if="isModalOpen" :index="index" :year="year" />
  </div>
</template>
<script scoped>
import images from "@/assets/imageurls.json";
import Card from "./Card";
import { mutations, store } from "@/store.js";
import ImageModal from "./ImageModal";

export default {
  name: "Images",
  props: ["year"],
  components: {
    Card,
    ImageModal,
  },
  data() {
    return {
      imageFolder: images.folder,
      item: null,
      index: null,
    };
  },
  methods: {
    closeModal: mutations.toggleModal,
    toggle() {
      mutations.toggleModal();
    },
    passItem(event, index) {
      this.item = event;
      this.index = index;
    },
    passIndex(index) {
      this.index = index;
    },
  },
  computed: {
    filteredImageFolder() {
      return this.imageFolder.filter((i) => {
        return i.year === this.year;
      });
    },
    isModalOpen() {
      return store.isModalOpen;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import url("https://fonts.googleapis.com/css2?family=Unica+One&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.year-text {
  position: relative;
  font-family: "Unica One", cursive;
  font-size: 150px;
  color: black;
  margin-bottom: 0;
  margin-left: 10px;
}
.imagegrid {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.slide-in-enter {
  opacity: 0;
  transform: scale(0.5);
}
.slide-in-enter-active {
  transition: all 1s ease;
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1059px) {
  .year-text {
    font-size: 100px;
  }
  .grid-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .year-text {
    font-size: 50px;
  }
  .grid-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>

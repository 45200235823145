<template>
  <Article>
    <template v-slot:side-image>
      <img
        loading="lazy"
        class="side-image"
        src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fuvdal_farger_P-3.jpg?alt=media&token=7ace4c1b-d865-496a-a882-8f338617aedb"
      />
    </template>
    <template v-slot:title> UVDAL </template>
    <template v-slot:undertitle>Jønndalen - 2018 </template>
    <template v-slot:start-text> Nore og Uvdal</template>
    <template v-slot:text-1>
      , nærmere bestemt Jønndalen i Uvdal ligger hytta til min svigerfamilie.
      Hytta ligger på grensen til flotte Hardangervidda. Fjellet forandrer seg
      ved ulike årstider, slik at nærturene oppleves forskjellig for hver gang
      vi er på besøk.
    </template>
    <template v-slot:photogrid-1>
      <Photogrid>
        <template v-slot:image-1>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fuvdal_stein_P.jpg?alt=media&token=28b3cd70-b123-41df-8930-e48d4d6bb1f0"
            style="width: 100%"
        /></template>
        <template v-slot:image-2>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fuvdal_host_L.jpg?alt=media&token=76a7c481-a1a0-4620-936c-0c7ad4003f22"
            style="width: 100%"
        /></template>
        <template v-slot:image-3>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fuvdal_farger_P-2.jpg?alt=media&token=397b7b8f-8f63-456b-9b57-0d94a8553db2"
            style="width: 100%"
        /></template>
        <template v-slot:image-4>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fuvdal_host_L-3.jpg?alt=media&token=be5e4e26-5b5b-4039-a4b5-fe442d0cc668"
            style="width: 100%"
        /></template> </Photogrid
    ></template>
    <template v-slot:start-text-2>Turer:</template>
    <template v-slot:text-2>
      Kistenuten, Storhovde, Rundhovda, Kruketjønne, Krukenuten, Fiskeløktjønne,
      Rundenuten, Skinnuten, Skarvsvatnet, Ulvelilægeret, Tøddølvannet,
      Beltenuten, Store Nordmannsslepa, besøk dyregraver, Uvdal Alpinsenter,
      Mårbu og Imingfjell.
    </template>
    <template v-slot:photogrid-2>
      <Photogrid>
        <template v-slot:image-1>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fprincess_glad_P.jpg?alt=media&token=26c3671d-4c3c-4883-aaf9-a5c6bed1b6cd"
            style="width: 100%"
        /></template>
        <template v-slot:image-2>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Fuvdal_vann_L.jpg?alt=media&token=630c7fb5-0401-43e1-be75-f295f3fbf232"
            style="width: 100%"
        /></template>
        <template v-slot:image-3>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2Ffarger_uvdal_P.jpg?alt=media&token=98959d7e-f6d4-4d96-a31e-0b412c958ff8"
            style="width: 100%"
        /></template>
        <template v-slot:image-4>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/Uvdal%2FIMG_6927.jpg?alt=media&token=ff249fc2-f78c-48c2-96fe-997ff7df9c82"
            style="width: 100%"
        /></template> </Photogrid
    ></template>
  </Article>
</template>

<script>
import Article from "./article";
import Photogrid from "./photogrid.vue";

export default {
  components: {
    Article,
    Photogrid,
  },
};
</script>

<style>
</style>
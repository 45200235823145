<template>
  <div tabindex="0" class="card" :class="gridClass" :style="gridStyle"></div>
</template>
<script>
export default {
  name: "Card",
  props: ["item", "index"],
  computed: {
    gridStyle() {
      return {
        "background-image": "url(" + this.item.src + ")",
      };
    },
    gridClass() {
      return {
        "card-1-1": this.item.grid === "card-1-1",
        "card-2-1": this.item.grid === "card-2-1",
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
}
.card:focus {
  outline-color: black;
}
.card:hover {
  cursor: pointer;
}
.card-1-1 {
  grid-column: span 1;
  height: 500px;
}
.card-2-1 {
  grid-row: span 2;
}
@media screen and (max-width: 800px) {
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .card-2-1 {
    width: 100%;
    height: 1065px;
  }
}
</style>

<template>
  <Navigation>
    <ul class="sidebar-panel-nav">
      <li>
        <a @click="closeSidebarPanel">
          <router-link to="/">
            <img
              aria-hidden="true"
              loading="lazy"
              class="logoimg-large"
              src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/logo.png?alt=media&token=6f827ec0-587a-46bc-97b2-2d64e9a035c9"
            />
          </router-link>
        </a>
      </li>
      <li>
        <a @click="closeSidebarPanel">
          <router-link to="/"> Hjem </router-link>
        </a>
      </li>
      <li>
        <a
          @click="openNavDropdownTwo"
          @keyup.enter="openNavDropdownTwo"
          tabindex="0"
          >Fotoarkiv</a
        >
        <div v-if="isNavDropdownOpenTwo">
          <ul class="dropdown-ul" data-aos="fade-in" data-aos-duration="1000">
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/yearbook/2022">2022</router-link>
              </a>
            </li>
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/yearbook/2021">2021</router-link>
              </a>
            </li>
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/yearbook/2020">2020</router-link>
              </a>
            </li>
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/yearbook/2019">2019</router-link>
              </a>
            </li>
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/yearbook/2018">2018</router-link>
              </a>
            </li>
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/yearbook/2017">2017</router-link>
              </a>
            </li>
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/yearbook/eldre">Eldre bilder</router-link>
              </a>
            </li>
          </ul>
        </div>
        <i class="fa fa-caret-down"></i>
      </li>
      <li>
        <a @click="openNavDropdown" @keyup.enter="openNavDropdown" tabindex="0"
          >Mine turer</a
        >
        <div v-if="isNavDropdownOpen">
          <ul class="dropdown-ul" data-aos="fade-in" data-aos-duration="1000">
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/uvdal"> Uvdal </router-link>
              </a>
            </li>
            <li>
              <a @click="closeSidebarPanel">
                <router-link to="/bremanger"> Bremanger </router-link>
              </a>
            </li>
          </ul>
        </div>
        <i class="fa fa-caret-down"></i>
      </li>
    </ul>
  </Navigation>
</template>
<script>
import { mutations, store } from "@/store.js";
import Navigation from "@/components/Menu/Navigation.vue";
export default {
  name: "MainNav",
  components: {
    Navigation,
  },
  methods: {
    closeSidebarPanel: mutations.toggleNav,
    openNavDropdown: mutations.toggleNavDropdown,
    openNavDropdownTwo: mutations.toggleNavDropdownTwo,
  },
  computed: {
    isNavDropdownOpen() {
      return store.isNavDropdownOpen;
    },
    isNavDropdownOpenTwo() {
      return store.isNavDropdownOpenTwo;
    },
  },
};
</script>
<style scoped>
ul.sidebar-panel-nav,
.dropdown-ul {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}
ul.sidebar-panel-nav > li > a:hover {
  cursor: pointer;
}

.dropdown-ul {
  margin-bottom: 1em;
}
.dropdown-ul li {
  margin-bottom: 0.5em;
}
.dropdown-ul a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
  padding-bottom: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 1059px) {
  .dropdown-ul a {
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  .dropdown-ul a {
    font-size: 15px;
  }
}
</style>

<template>
  <div>
    <div class="text-on-image" v-for="item in items" :key="item.title">
      <a>
        <router-link :to="item.link">
          <img class="image" :src="item.src" loading="lazy" :alt="item.alt" />

          <div
            :id="item.title"
            class="text"
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1500"
            :data-aos-anchor-placement="item.title"
          >
            {{ item.tittel }}
          </div>
        </router-link>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.text-on-image {
  position: relative;
  margin: 8vw;
}
.text-on-image a {
  font-size: 3vw;
  font-weight: 400;
}
.image {
  width: 100%;
  height: auto;
}
.text {
  background-color: white;
  padding: 2%;
  text-align: right;
  min-width: 30%;
  height: auto;
  position: absolute;
  top: 44%;
}
</style>
<template>
  <div class="infobox">
    <div class="image-container">
      <div class="main-text">
        <p class="big-text"></p>
        {{ randomPoem.text }}
        <p class="sitat">{{ randomPoem.forfatter }}</p>
      </div>
      <img
        alt="sand som blir truffet av sol slik at sanden blir rød-orange"
        loading="lazy"
        class="square"
        :src="randomPoem.src"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CenteredContendCard",
  components: {},
  data() {
    return {
      dikt: [
        {
          title: "Ja visst gör det ont",
          text: "Ja visst gör det ont när knoppar brister. Varför skulle annars våren tveka?",
          forfatter: "- Karin Boye",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2020%2FIMG_6353-3.jpg?alt=media&token=8ae6fb7a-3703-4b28-b1c7-83d6e440348d",
        },
        {
          title: "",
          text: "Eg ha klivi høgt te fjells sprunge heile vegen ned sjølv når eg er langt te skogs ser eg berre deg.",
          forfatter: "- Stein Torleif Bjella",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/forside%2Fmain_image_L.jpg?alt=media&token=019cff8d-a2f3-4c6b-911d-62eb5254b6cb",
        },
        {
          title: "",
          text: "Ikke vær redd for mørket, for lyset hviler der",
          forfatter: "- Linda Olsson",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2020%2FIMG_7758.jpg?alt=media&token=2e657a9b-f6be-4574-b398-ccbbaf86bcbe",
        },
        {
          title: "",
          text: "Det snør himmelsk korrekturlakk over feilstavet sommer",
          forfatter: "- Anne Grete Preus",
          src: "https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2022%2FDSC02796.jpg?alt=media&token=14094c8e-7d9d-452a-836a-2134dabf057d",
        },
      ],
    };
  },
  computed: {
    randomPoem() {
      return this.dikt[Math.floor(Math.random() * this.dikt.length)];
    },
  },
};
</script>

<style scoped>
@import url("https://use.typekit.net/djf0wvu.css");
.infobox {
  margin: 0 auto;
  margin-bottom: 2vw;
  font-family: freight-neo-pro, sans-serif;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.image-container {
  margin: 8vw;
}
.main-text {
  z-index: 0;
  font-size: 5vw;
  font-weight: 700;
  margin: 8vw;
  position: absolute;
  top: 63px;
  left: 16px;
  color: #4a686d;
  background: linear-gradient(to left, #f5998a, #4a686d, #919092);
  background-size: 1600px 200px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 6s;
  animation-name: animationgradienttitle;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 6s;
  -webkit-animation-name: animationgradienttitle;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
}

.big-text {
  font-size: 6vw;
  font-family: freight-neo-pro, sans-serif;
}
.sitat {
  font-family: freight-neo-pro, sans-serif;
  font-style: italic;
  margin-top: 2%;
}

.square {
  z-index: -2;
  margin-top: 8vw;
  width: 50%;
  overflow: hidden;
  position: relative;
  margin-left: 49%;
}
@media only screen and (max-width: 600px) {
  .square {
    margin-top: 20vw;
  }
}
@-webkit-keyframes animationgradienttitle {
  0% {
    background-position: 0 1600px;
  }
  100% {
    background-position: 1600px 0;
  }
}
@keyframes animationgradienttitle {
  0% {
    background-position: 0 1600px;
  }
  100% {
    background-position: 1600px 0;
  }
}
@-moz-keyframes animationgradienttitle {
  0% {
    background-position: 0 1600px;
  }
  100% {
    background-position: 1600px 0;
  }
}
</style>
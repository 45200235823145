<template>
  <div class="article">
    <div class="grid-container">
      <div class="item1">
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
          data-aos-delay="1500"
        >
          <slot name="side-image" />
        </div>
      </div>
      <div
        class="item2"
        data-aos="fade-down"
        data-aos-easing="ease-in-sine"
        data-aos-duration="2000"
        data-aos-delay="1500"
      >
        <p class="title"><slot name="title" /></p>
        <p class="undertitle"><slot name="undertitle" /></p>
      </div>

      <div class="item3">
        <div
          data-aos="fade"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1500"
          anchor-placement="item3"
        >
          <span class="start-text">
            <slot name="start-text" />
          </span>
          <slot name="text-1" />
        </div>
      </div>
      <div class="item4">
        <slot name="photogrid-1" />
      </div>
      <div class="item5">
        <div
          data-aos="fade"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1500"
          anchor-placement="item5"
        >
          <span class="start-text">
            <slot name="start-text-2" />
          </span>
          <slot name="text-2" />
        </div>
      </div>
      <div class="item6">
        <slot name="photogrid-2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Article",
  components: {},
};
</script>

<style >
@import url("https://use.typekit.net/djf0wvu.css");
.article {
  overflow: hidden;
  font-family: freight-neo-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 65px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  color: #504e4c;
}
@media only screen and (min-width: 1200px) {
  .article {
    max-width: 1200px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
.side-image {
  width: 100%;
  height: auto;
}
.item1 {
  grid-area: side-image;
  margin-top: 8vw;
}
.item2 {
  margin-top: 16vw;
  grid-area: title;
  padding: 15px;
  padding-right: 5.5vw;
  text-align: left;
  writing-mode: vertical-rl;
}
.item2 .title {
  font-weight: 400;
  font-size: 11.111vw;
}
.item2 .undertitle {
  font-weight: 200;
  font-size: 7vw;
}
.item3 {
  grid-area: text;
  margin: 8vw;
}
.item4 {
  grid-area: photogrid;
}
.item5 {
  grid-area: text-2;
  margin: 8vw;
}
.item6 {
  grid-area: photogrid-2;
  margin-bottom: 15vw;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "side-image side-image side-image side-image side-image title"
    "text text text text text text"
    "photogrid photogrid photogrid photogrid photogrid photogrid"
    "text-2 text-2 text-2 text-2 text-2 text-2"
    "photogrid-2 photogrid-2 photogrid-2 photogrid-2 photogrid-2 photogrid-2";
}
.grid-container > div {
  font-size: 30px;
}
.start-text {
  font-size: 35px;
  font-weight: 700;
}
</style>
<template>
  <Article>
    <template v-slot:side-image>
      <img
        loading="lazy"
        class="side-image"
        src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_5960-2.jpg?alt=media&token=1e3c7bfb-a5fd-4b24-afca-df7c3f015172"
      />
    </template>
    <template v-slot:title> Bremanger </template>
    <template v-slot:undertitle>Bremanger - 2020-2021 </template>
    <template v-slot:start-text> Bremanger</template>
    <template v-slot:text-1>
      , nærmere bestemt Berle ligger hytta til mine fosterforeldre. Hytta ligger
      ut mot havet med sterke værkontraster. En halvtime fra Berle ligger Kalvåg
      med fantastiske Knutholmen(digg mat). Bremangerlandet byr på ekstreme
      naturopplevelser Vakreste stedet på jord .
    </template>
    <template v-slot:photogrid-1>
      <Photogrid>
        <template v-slot:image-1>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_5405.jpg?alt=media&token=cc25f115-5321-4047-8b65-53621df8237c"
            style="width: 100%"
        /></template>
        <template v-slot:image-2>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_5852.jpg?alt=media&token=bfd4dd78-21e4-4834-a810-d6f54bbab567"
            style="width: 100%"
        /></template>
        <template v-slot:image-3>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_5418.jpg?alt=media&token=ba62c3a5-3b1f-49be-8cdf-c6757e2bd773"
            style="width: 100%"
        /></template>
        <template v-slot:image-4>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_7399.jpg?alt=media&token=1b11a1f8-0faf-4448-a75a-c1f910b49f24"
            style="width: 100%"
        /></template> </Photogrid
    ></template>
    <template v-slot:start-text-2>Turer:</template>
    <template v-slot:text-2>
      Frøya rundt, Vetvika, fiske, Berle, Hælkinna, Hornelen, Grotlesanden,
      sykkelturer, Veten
    </template>
    <template v-slot:photogrid-2>
      <Photogrid>
        <template v-slot:image-1>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_5695.jpg?alt=media&token=a4c6e65d-d745-4679-898b-37db9311bd82"
            style="width: 100%"
        /></template>
        <template v-slot:image-2>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_7758.jpg?alt=media&token=e702d36a-c6ab-4f39-b639-3aa131eea6d3"
            style="width: 100%"
        /></template>
        <template v-slot:image-3>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_7582.jpg?alt=media&token=2f43df3b-00e0-4470-8e12-9f65c14c5d91"
            style="width: 100%"
        /></template>
        <template v-slot:image-4>
          <img
            loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/ceciliefoto-a3879.appspot.com/o/2021%2Fvestlandet%2FIMG_5793.jpg?alt=media&token=217690ff-0b0f-4f95-b5b7-a180e508ddc2"
            style="width: 100%"
        /></template> </Photogrid
    ></template>
  </Article>
</template>

<script>
import Article from "./article";
import Photogrid from "./photogrid.vue";

export default {
  components: {
    Article,
    Photogrid,
  },
};
</script>

<style>
</style>
<template>
  <div class="ImageCarousel">
    <span class="close" @click="closeModal" role="close"> </span>
    <div class="numbertext">{{ i }} / {{ getImageFolderLength[0] }}</div>
    <div class="container">
      <div v-for="image in thisImage" :key="image.id">
        <div class="fade" v-if="isPortrait(image)">
          <img
            class="modal-content"
            :src="image.src"
            :alt="image.alt"
            loading="lazy"
            style="max-width: 300px"
          />
          <button class="prev" @click="prev" :disabled="i === 0">
            &#10094;
          </button>
          <button class="next" @click="next">&#10095;</button>
          <div id="caption">
            <p>{{ image.alt }}</p>
          </div>
        </div>
        <div class="fade" v-else>
          <div v-for="image in thisImage" :key="image.id">
            <img
              class="modal-content"
              :src="image.src"
              :alt="image.alt"
              loading="lazy"
            />
            <button class="prev" @click="prev" :disabled="i === 0">
              &#10094;
            </button>
            <button class="next" @click="next">&#10095;</button>
            <div id="caption">
              <p>{{ image.alt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mutations } from "@/store.js";
import images from "@/assets/imageurls.json";

export default {
  name: "ImageCarousel",
  data() {
    return {
      imageFolder: images.folder,
      i: this.index,
    };
  },
  props: ["year", "index"],
  methods: {
    next() {
      this.i = (this.i + 1) % this.getImageFolderLength;
    },
    prev() {
      this.i = (this.i - 1) % this.getImageFolderLength;
    },
    closeModal: mutations.toggleModal,
    isPortrait(item) {
      return item.orientation === "portrait";
    },
  },
  computed: {
    filteredImageFolderYear() {
      return this.imageFolder.filter((i) => {
        return i.year === this.year;
      });
    },
    getImageFolderLength() {
      return this.filteredImageFolderYear.map((i) => {
        const num = i.images.length;
        return num;
      });
    },
    thisImage() {
      return this.filteredImageFolderYear.map((i) => {
        return i.images[this.i];
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/* The Modal (background) */

.ImageCarousel {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  user-select: none;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0); /* Black w/ opacity */
}
.container {
  position: relative;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
button:disabled,
button[disabled] {
  color: #666666;
  cursor: not-allowed;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  color: grey;
}
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  margin: 5px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
